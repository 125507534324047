import logo from './logo.svg';
import './App.css';
import { Outlet, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Nav from './layout/Navbar';
import { httpGetRequest } from './HttpRequestService';
import { useState } from 'react';

function App() {
  let params = useParams();

  const token = params.token;
  const validToken = token.trim() !== "" ? true : false;

  return (
    <Container>
      {validToken && <>
        <Nav />
        <div className="App">
          <Outlet context={[token]}/>
        </div>
        <Row>
          <Col className="text-center mt-5">
            GATEWAY SLife Tracker - Moyen de paiement en ligne - Contact : 034 21 941 55
          </Col>
        </Row>
      </>}
    </Container>
  );
}

export default App;
