import { Nav } from "react-bootstrap";
import { useParams } from "react-router-dom";

const Navbar = (props) => {
  let params = useParams();

  const token = params.token;

  return (
    <Nav className="justify-content-center" activeKey="/home">
      <Nav.Item>
        <Nav.Link eventKey="trx" href={`/${token}/home`}>Transaction</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="smsReceive" href={`/${token}/smsReceive`}>SMS reçus</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="myPasserelle" href={`/${token}/myPasserelle`}>Mes passerelle</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="api">
          APIs
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

export default Navbar;