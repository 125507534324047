import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";



const Accueil = (props) => {
    const [token, setToken] = useState("");
    const navigation = useNavigate()

    const navigate = () => {
        navigation(`/${token}/home`);
    }

    return (
        <>
            <br/>
            <br/>
            <br/>
            <Row>
                <Col xs={1}>
                    
                </Col>
                <Col>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Entrez votre token si vous êtes un utilisateur</strong></Form.Label>
                            <Form.Control type="text" placeholder="Token" value={token} onChange={(event)=>setToken(event.target.value)}/>
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={navigate}>
                            Continuer
                        </Button>
                    </Form>
                </Col>
                <Col xs={1}>
                    
                </Col>
            </Row>
        </>
    )
}

export default Accueil;