import { useEffect, useState } from "react";
import { Button, Col, Form, Nav, Row, Card } from "react-bootstrap";
import { useLocation, useOutletContext } from "react-router-dom";
import httpRequest, { httpGetRequest } from "./HttpRequestService";
import Trx from "./layout/Trx";
import SmsR from "./layout/SmsR";
import Pass from "./layout/Pass";



const Passerelle = (props) => {
    const [token] = useOutletContext();
    const [data, setData] = useState([]);

    const load = async (filtre = null) => {
        try {
            const response = await httpGetRequest(`${token}/myPasserelle`, false);
            if (response.data) {
                setData(response.data);
            }
            //setLoading(false);
        } catch (error) {
            //setLoading(false);
            //setErreur(error.response.data.message);
            console.log(error.response.data);
        }
    }

    useEffect(async () => {
        load();
    }, []);

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <Button variant="success" type="button" onClick={() => load()} >
                            Actualiser
                        </Button>
                    </Form>
                </Col>
            </Row>


            {data.map((d, i) => <Card className="mt-2" key={i}>
                <Card.Header>{d.name} ({d.isConnected ? <span className="text-success">Connecté</span> : <span className="text-danger">déconnecté</span>})</Card.Header>
                <Card.Body >
                    <Pass pass={d} key={i} load={load} token={token} />
                </Card.Body>
            </Card>)}
        </>
    )
}

export default Passerelle;