import axios from 'axios';

//const BASE_URL = "https://slifepay.slifetracker.com/";
const BASE_URL = "https://modem.slifetracker.com/admin/"; //"http://localhost:4040";//"

const httpRequest = (url, data, isAuth, isFile = false) => {
    let options = {
        headers: {
            'Content-Type' : !isFile ? 'application/json' : 'multipart/form-data'
        }
    };
    if(isAuth){
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        options.headers['Authorization'] = `Bearer ${userDetails.token}`
    }
    return axios.post(
        `${BASE_URL}${url}`,
        data,
        options
    );
}

export const httpGetRequest = (url, isAuth) => {
    let options = {
        headers: {
            'Content-Type' : 'application/json'
        }
    };
    if(isAuth){
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        options.headers['Authorization'] = `Bearer ${userDetails.token}`
    }
    return axios.get(
        `${BASE_URL}${url}`,
        options
    );
}

export default httpRequest;