import { useEffect, useState } from "react";
import { Button, Col, Form, Pagination, Row, Table } from "react-bootstrap";
import { useLocation, useOutletContext } from "react-router-dom";
import httpRequest, { httpGetRequest } from "./HttpRequestService";
import Trx from "./layout/Trx";



const Home = (props) => {
    const [token] = useOutletContext();
    const [data, setData] = useState([]);
    const [session, setSession] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [filter, setFilter] = useState({
        key: [],
        perpage: 10,
        page: 1,
        pages: 1,
        sort: [
            {
                title: 'createdAt',
                value: -1
            }
        ],
        limiter: [],
        metaFilters: []
    });

    const goPage = (page) => {
        console.log(page);
        let filtre_tmp = { ...filter, page: page };
        load(filtre_tmp);
    }
    const next = () => {
        if (filter.page < filter.pages) {
            let filtre_tmp = { ...filter, page: filter.page + 1 };
            load(filtre_tmp);
        }
    }
    const prev = () => {
        if (filter.page > 1) {
            let filtre_tmp = { ...filter, page: filter.page - 1 };
            load(filtre_tmp);
        }
    }
    const findByField = () => {
        const filtre = { ...filter, page: 1 };
        if (session != '') {
            filtre.key = [{
                title: 'public_id', value: session
            }];
        } else {
            filtre.key = [];
        }
        if (phoneNumber != '') {
            filtre.metaFilters = [{
                key: 'phoneNumber', value: phoneNumber
            }];
        } else {
            filtre.metaFilters = [];
        }
        load(filtre);
    }

    const load = async (filtre = null) => {
        try {
            const response = await httpRequest(`${token}/trx/list`, { filtre: filtre ? filtre : filter }, false);
            if (response.data) {
                setData(response.data.data);
                setFilter(response.data.filter);
            }
            //setLoading(false);
        } catch (error) {
            //setLoading(false);
            //setErreur(error.response.data.message);
            console.log(error.response.data);
        }
    }

    const confirm = async (ref, trxId) => {
        try {
            await httpRequest(`${token}/trx/confirm`, { ref, trxId }, false);
            load();
            //setLoading(false);
        } catch (error) {
            //setLoading(false);
            //setErreur(error.response.data.message);
            console.log(error.response.data);
        }
    }

    useEffect(async () => {
        load();
    }, []);

    let items = [];

    if(filter.page == filter.pages) {
        if(filter.pages > 1){
            items.push(
                <Pagination.Item onClick={() => goPage(filter.page - 1)} key={filter.page - 1} active={false} >
                    Prev
                </Pagination.Item>
            );
        }
    }

    if(filter.page > 1 && filter.pages > filter.page) {
        items.push(
            <Pagination.Item onClick={() => goPage(filter.page - 1)} key={filter.page - 1} active={false} >
                Prev
            </Pagination.Item>
        );
    }
    
    items.push(
        <Pagination.Item onClick={() => goPage(filter.page)} key={filter.page} active={true} >
            {filter.page}
        </Pagination.Item>
    );

    if(filter.page < filter.pages && filter.page > 1 ) {
        items.push(
            <Pagination.Item onClick={() => goPage(filter.page + 1)} key={filter.page + 1} active={false} >
                Next
            </Pagination.Item>
        );
    }

    if(filter.page == 1) {
        if(filter.pages > 1){
            items.push(
                <Pagination.Item onClick={() => goPage(filter.page + 1)} key={filter.page + 1} active={false} >
                    Next
                </Pagination.Item>
            );
        }
    }

    /* for (let number = 1; number <= filter.pages; number++) {
        items.push(
            <Pagination.Item onClick={() => goPage(number)} key={number} active={number === filter.page} >
                {number}
            </Pagination.Item>
        );
    } */

    return (
        <>
            <Row>
                <Col>
                    <div className="form-group mb-3">
                        <input type="date" value={filter.limiter.find(l => l.title === "createdAt")?.min} onChange={(event) => {
                            let limiter_tmp = [...filter.limiter];
                            const limit = limiter_tmp.find(l => l.title === "createdAt");
                            if (limit) {
                                limiter_tmp[limiter_tmp.indexOf(limit)].min = event.target.value;
                            } else {
                                limiter_tmp.push({
                                    title: "createdAt",
                                    min: event.target.value,
                                    max: null,
                                });
                            }
                            setFilter({ ...filter, limiter: limiter_tmp });
                        }} className="form-control mb-3" />
                    </div>
                </Col>
                <Col>
                    <div className="form-group mb-3">
                        <input type="date" value={filter.limiter.find(l => l.title === "createdAt")?.max} onChange={(event) => {
                            let limiter_tmp = [...filter.limiter];
                            const limit = limiter_tmp.find(l => l.title === "createdAt");
                            if (limit) {
                                limiter_tmp[limiter_tmp.indexOf(limit)].max = event.target.value;
                            } else {
                                limiter_tmp.push({
                                    title: "createdAt",
                                    min: null,
                                    max: event.target.value,
                                });
                            }
                            setFilter({ ...filter, limiter: limiter_tmp });
                        }} className="form-control" />
                    </div>
                </Col>
            </Row >
            <Row>
                <Col>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="SESSION" onChange={(event) => setSession(event.target.value)} />
                        </Form.Group>
                    </Form>
                </Col>
                <Col>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Phone du paiement" onChange={(event) => setPhoneNumber(event.target.value)} />
                        </Form.Group>
                    </Form>
                </Col>
                <Col>
                    <Form>
                        <Button variant="primary" type="button" onClick={findByField} style={{marginRight: '5px'}}>
                            Recherche
                        </Button>
                        <Button variant="success" type="button" onClick={()=>load()} >
                            Actualiser
                        </Button>
                    </Form>
                </Col>
            </Row>
            
            <Row className="mt-2">
                <Col>
                    <Pagination>{items}</Pagination>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Session</th>
                                <th>API</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Paiement</th>
                                <th>Status</th>
                                <th>OrderId</th>
                                <th>Passerelles</th>
                                <th>PreCode</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((dt, index) =>
                                <Trx key={index} dt={dt} load={load} />
                            )}
                        </tbody>
                    </Table>
                    <Pagination>{items}</Pagination>
                </Col>
            </Row>
        </>
    )
}

export default Home;